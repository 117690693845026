import React from 'react'
// import { StaticImage } from 'gatsby-plugin-image'

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-body">
      {/* <StaticImage
          src="../assets/.png"
          width={32}
          height={32}
          alt=""
          className="footer__img"
        /> */}
        <p className="title space">
          Hero title
        </p>
        <p className="subtitle">
          Hero subtitle
        </p>
        <a href="/rss.xml" target="_blank" className="hero-link">rss link</a>
      </div>
  </section>
  )
}

export default Hero
